<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="site_settings_dialog" persistent max-width="800px">
        <v-card>
          <v-form
            ref="siteSettingsForm"
            v-model="siteSettingsFormValid"
            lazy-validation
            @submit.prevent="submitSiteSettings"
          >
            <v-card-title>
              <span class="text-h5">更改网站信息</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-text-field
                  label="*公司地址"
                  v-model="company_address"
                  outlined
                  dense
                  required
                  :rules="[(v) => !!v || '请填写公司地址']"
                ></v-text-field>
                <v-divider class="mt-1 mb-7"></v-divider>
                <v-text-field
                  label="*公司邮箱"
                  v-model="company_email"
                  outlined
                  dense
                  type="email"
                  required
                  hint="在网站的“联系我们”板块中的在线留言信息将会发送到此邮箱。"
                  persistent-hint
                  :rules="[(v) => !!v || '请填写公司邮箱', (v) => /.+@.+\..+/.test(v) || '邮件地址无效']"
                ></v-text-field>
                <v-divider class="mt-1 mb-7"></v-divider>
                <v-text-field
                  label="*公司电话"
                  v-model="company_phone"
                  outlined
                  dense
                  required
                  :rules="[(v) => !!v || '请填写公司电话']"
                ></v-text-field>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="close_site_settings_dialog">取消</v-btn>
              <v-btn color="success" type="submit" :disabled="!siteSettingsFormValid">保存</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar centered elevation="24" v-model="snackbar" timeout="5000">
      {{ toast_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">关闭</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "UpdateSiteSettings",
  data() {
    return {
      site_settings_dialog: false,
      siteSettingsFormValid: false,
      company_address: null,
      company_email: null,
      company_phone: null,
      snackbar: false,
      toast_text: "",
    };
  },

  methods: {
    ...mapMutations(["setCompanyProfile"]),

    open_site_settings_dialog() {
      if (this.$refs.siteSettingsForm) {
        this.$refs.siteSettingsForm.reset();
      }
      this.company_address = this.company_profile.company_address;
      this.company_email = this.company_profile.company_email;
      this.company_phone = this.company_profile.company_phone;

      this.site_settings_dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    close_site_settings_dialog() {
      this.site_settings_dialog = false;
      this.$refs.siteSettingsForm.reset();
      this.reject();
    },

    submitSiteSettings() {
      if (this.$refs.siteSettingsForm.validate()) {
        this.$api
          .put("/site-config/1", {
            company_address: this.company_address,
            company_email: this.company_email,
            company_phone: this.company_phone,
          })
          .then((response) => {
            this.setCompanyProfile({
              company_address: response.data.company_address,
              company_email: response.data.company_email,
              company_phone: response.data.company_phone,
            });
            this.$refs.siteSettingsForm.reset();
            this.site_settings_dialog = false;
            this.resolve(true);
          })
          .catch(() => {
            this.toast_text = "更新网站信息失败，请稍后再试";
            this.snackbar = true;
          });
      }
    },
  },
  computed: {
    ...mapState(["company_profile"]),
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
