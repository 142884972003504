<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="search_product_dialog" persistent max-width="1000px">
        <v-card>
          <v-form ref="searchingForm" v-model="searchingFormValid" lazy-validation @submit.prevent="submitSearching">
            <v-card-title>
              <span class="text-h6">搜索产品</span>
              <v-spacer></v-spacer>
              <v-btn icon color="indigo" @click="close_search_product_dialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-text-field
                  label="请输入产品名称或产品编号搜索产品"
                  v-model="keyword"
                  solo
                  scrollable
                  outlined
                  required
                  prepend-inner-icon="mdi-magnify"
                  :rules="[(v) => !!v || '请输入搜索关键字']"
                  class="pr-0"
                >
                  <template v-slot:append>
                    <v-btn
                      depressed
                      elevation="0"
                      color="primary"
                      type="submit"
                      :disabled="!searchingFormValid"
                      height="56px"
                      class="mr-n3 rounded-l-0"
                    >
                      搜索
                    </v-btn>
                  </template>
                </v-text-field>
                <v-divider class="my-3"></v-divider>
                <div>
                  <v-row>
                    <v-col v-for="(product_item, i) in products" :key="i" class="d-flex child-flex" cols="12" md="4">
                      <v-card
                        color=""
                        tile
                        hover
                        :to="{ name: 'productDetail', params: { product_uri: product_item.uri } }"
                        @click="close_search_product_dialog"
                      >
                        <v-hover v-slot="{ hover }">
                          <v-img :src="product_item.picture[0]" aspect-ratio="1" class="">
                            <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="red"></v-progress-circular>
                              </v-row>
                            </template>
                            <v-expand-transition>
                              <div
                                v-if="hover"
                                class="d-flex transition-fast-in-fast-out v-card--reveal white--text text-center justify-center align-center"
                                style="height: 100%; background-color: rgba(214, 0, 15, 0.8)"
                              >
                                {{ product_item.title }}
                              </div>
                            </v-expand-transition>
                          </v-img>
                        </v-hover>
                        <v-card-text class="text-center">{{ product_item.title }}</v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
                <div class="text-center" v-if="loading">
                  <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
                </div>
                <div class="text-center py-5" v-if="not_found && keyword">
                  <h3>没有找到任何关于 “{{ keyword }}” 的产品。</h3>
                </div>
                <div class="text-center py-5" v-if="error">
                  <h3>搜索产品出现错误，请稍后再试试。</h3>
                </div>
              </v-container>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SearchProduct",
  data() {
    return {
      search_product_dialog: false,
      searchingFormValid: false,
      loading: false,
      keyword: null,
      products: [],
      not_found: false,
      error: false,
    };
  },

  methods: {
    open_search_product_dialog() {
      if (this.$refs.searchingForm) {
        this.$refs.searchingForm.reset();
      }
      this.products = [];
      this.search_product_dialog = true;
    },

    close_search_product_dialog() {
      this.search_product_dialog = false;
      this.$refs.searchingForm.reset();
    },

    submitSearching() {
      if (this.$refs.searchingForm.validate()) {
        this.loading = true;
        this.not_found = false;
        this.error = false;
        this.$api
          .get("/product?search=" + this.keyword)
          .then((response) => {
            this.products = response.data;
            this.loading = false;
            if (this.products.length > 0) {
              this.not_found = false;
            } else {
              this.not_found = true;
            }
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.error = true;
          });
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
