<template>
  <v-app>
    <site-header />

    <v-main>
      <router-view />
    </v-main>

    <site-footer />
    <site-widget />
  </v-app>
</template>

<script>
import SiteHeader from "./components/SiteHeader";
import SiteFooter from "./components/SiteFooter.vue";
import SiteWidget from "./components/SiteWidget.vue";

export default {
  name: "App",

  data: () => ({}),
  components: {
    SiteHeader,
    SiteFooter,
    SiteWidget,
  },
};
</script>
