<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="upload_carousel_image_dialog" persistent width="1000px">
        <v-card>
          <v-card-title>
            <span class="text-h5">设置首页轮播图片</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-file-input
                label="选择图片"
                ref="carouselImageInput"
                outlined
                dense
                show-size
                required
                truncate-length="20"
                prepend-icon=""
                prepend-inner-icon="mdi-camera"
                accept=".jpg, .jpeg, .png"
                :rules="[(v) => !!v || '请选择图片', (v) => !v || v.size < 1000000 || '图片过大，请上传小于1 MB的图片']"
                @change="onCarouselImageFileChange"
              ></v-file-input>
              <v-progress-linear
                rounded
                indeterminate
                color="cyan"
                :active="show_carousel_image_uploading"
                class="my-2"
              ></v-progress-linear>
              <div>
                <draggable v-model="show_carousel_images" class="d-flex flex-row flex-wrap justify-start">
                  <div v-for="(image_link, i) in show_carousel_images" :key="i" class="pa-1" style="width: 450px">
                    <v-card>
                      <v-img :src="image_link" contain>
                        <div class="float-left ma-1">
                          <v-btn fab dark x-small color="primary">
                            <v-icon dark>{{ "mdi-numeric-" + (i + 1) + "-circle" }}</v-icon>
                          </v-btn>
                        </div>
                        <div class="float-right ma-1">
                          <v-tooltip v-if="is_login" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                fab
                                dark
                                x-small
                                color="error"
                                v-bind="attrs"
                                v-on="on"
                                @click="remove_carousel_image(i)"
                              >
                                <v-icon dark>mdi-close</v-icon>
                              </v-btn>
                            </template>
                            <span>删除图片</span>
                          </v-tooltip>
                        </div>
                      </v-img>
                    </v-card>
                  </div>
                </draggable>
              </div>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="close_upload_carousel_image_dialog">取消</v-btn>
            <v-btn color="success" @click="done_upload_carousel_image">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar centered elevation="24" v-model="snackbar" timeout="5000">
      {{ toast_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">关闭</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
export default {
  name: "UploadCarouselImage",
  components: { draggable },

  data() {
    return {
      show_carousel_images: [],
      upload_carousel_image_dialog: false,
      show_carousel_image_uploading: false,
      snackbar: false,
      toast_text: "",
    };
  },

  methods: {
    open_upload_carousel_image_dialog() {
      this.upload_carousel_image_dialog = true;
      if (this.$refs.carouselImageInput) {
        this.$refs.carouselImageInput.reset();
      }
      this.show_carousel_images = [];
      this.get_site_carousel_images();
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    remove_carousel_image(index) {
      this.show_carousel_images.splice(index, 1);
    },

    close_upload_carousel_image_dialog() {
      this.upload_carousel_image_dialog = false;
      this.$refs.carouselImageInput.reset();
      this.reject();
    },

    done_upload_carousel_image() {
      this.upload_carousel_image_dialog = false;
      this.$refs.carouselImageInput.reset();
      this.resolve(this.show_carousel_images);
    },

    get_site_carousel_images() {
      this.$api
        .get("/site-config/1")
        .then((response) => {
          if (response.data) {
            this.show_carousel_images = response.data.carousel_images;
          } else {
            this.toast_text = "读取当前轮播图片失败，请稍后再试";
            this.snackbar = true;
          }
        })
        .catch(() => {
          console.log("获取配置失败");
          this.toast_text = "读取当前轮播图片失败，请稍后再试";
          this.snackbar = true;
        });
    },

    onCarouselImageFileChange(imageFile) {
      if (imageFile && this.$refs.carouselImageInput.validate()) {
        this.show_carousel_image_uploading = true;
        let formData = new FormData();
        formData.append("image", imageFile);
        this.$api
          .post("/image-upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.show_carousel_image_uploading = false;
            this.$refs.carouselImageInput.reset();
            this.show_carousel_images.push(response.data.image);
          })
          .catch(() => {
            this.show_carousel_image_uploading = false;
            this.toast_text = "上传图片失败，请稍后再试";
            this.snackbar = true;
          });
      }
    },
  },

  computed: {
    ...mapState(["is_login"]),
  },
};
</script>
