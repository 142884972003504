import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    company_profile: {
      company_name: "安徽龙路家居用品有限公司",
      company_address: null,
      company_email: null,
      company_phone: null,
    },
    catalogs: null,
    is_login: false,
    carousel_images: [],
  },
  getters: {},
  mutations: {
    setLoginState(state, is_login) {
      state.is_login = is_login;
    },
    setCatalogs(state, newCatalogs) {
      state.catalogs = newCatalogs;
    },
    setCarouselImages(state, newImages) {
      state.carousel_images = newImages;
    },
    setCompanyProfile(state, newProfile) {
      if (newProfile.company_address) {
        state.company_profile.company_address = newProfile.company_address;
      }
      if (newProfile.company_email) {
        state.company_profile.company_email = newProfile.company_email;
      }
      if (newProfile.company_phone) {
        state.company_profile.company_phone = newProfile.company_phone;
      }
    },
  },
  actions: {},
  modules: {},
});
