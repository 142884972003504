<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="site_backup_dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h6">下载网站备份</span>
            <v-spacer></v-spacer>
            <v-btn icon color="indigo" @click="close_site_backup_dialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <div class="text-center py-5" v-if="!loading && !error && backup_file_url">
                <a :href="backup_file_url">
                  <v-btn color="primary" class="ma-2 white--text">
                    下载网站备份文件
                    <v-icon right dark>mdi-cloud-download</v-icon>
                  </v-btn>
                </a>
              </div>
              <div class="text-center" v-if="loading">
                <h3 class="py-5">正在获取网站备份文件，请稍候</h3>
                <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
              </div>
              <div class="text-center py-5" v-if="error">
                <h3>获取网站备份文件出现错误，请稍后再试试。</h3>
              </div>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "GetSiteBackup",
  data() {
    return {
      site_backup_dialog: false,
      loading: true,
      backup_file_url: "",
      error: false,
    };
  },

  methods: {
    open_site_backup_dialog() {
      this.site_backup_dialog = true;
      this.loading = true;
      this.error = false;
      this.$api
        .get("/get-site-backup-file", {
          timeout: 60000,
        })
        .then((response) => {
          this.backup_file_url = response.data.url;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.error = true;
        });
    },

    downloadFile() {
      axios({
        url: this.backup_file_url,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", url.substring(this.backup_file_url.lastIndexOf("/") + 1));
        document.body.appendChild(link);
        link.click();
      });
    },

    close_site_backup_dialog() {
      this.site_backup_dialog = false;
    },
  },
  computed: {},
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
