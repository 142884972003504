import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/catalog/:catalog_uri",
    name: "catalogList",
    component: () => import("../views/CatalogListView.vue"),
  },
  {
    path: "/product-edit/:product_uri",
    name: "productCreateUpdate",
    component: () => import("../views/ProductCreateUpdateView.vue"),
  },
  {
    path: "/product/:product_uri",
    name: "productDetail",
    component: () => import("../views/ProductDetailView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactView.vue"),
  },
  {
    path: "*",
    name: "404",
    component: () => import("../views/404View.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
});

router.beforeEach((to, from, next) => {
  document.title = "安徽龙路家居用品有限公司";
  next();
});

export default router;
