<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="change_password_dialog" persistent max-width="800px">
        <v-card>
          <v-form
            ref="changePasswordForm"
            v-model="changePasswordFormValid"
            lazy-validation
            @submit.prevent="submitChangePassword"
          >
            <v-card-title>
              <span class="text-h5">更改管理员密码</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-text-field
                  label="当前密码"
                  v-model="old_password"
                  type="password"
                  outlined
                  dense
                  required
                  :rules="[(v) => !!v || '请填写当前的管理员密码']"
                ></v-text-field>
                <v-text-field
                  label="设置新密码"
                  v-model="new_password"
                  type="password"
                  outlined
                  dense
                  required
                  :rules="[
                    (v) => !!v || '请填写一个新的管理员密码',
                    (v) => v !== old_password || '请填写一个与当前密码不一样的新密码',
                  ]"
                ></v-text-field>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="close_change_password_dialog">取消</v-btn>
              <v-btn color="success" type="submit" :disabled="!changePasswordFormValid">保存</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar centered elevation="24" v-model="snackbar" timeout="5000">
      {{ toast_text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">关闭</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "ChangePassword",
  data() {
    return {
      change_password_dialog: false,
      changePasswordFormValid: false,
      old_password: null,
      new_password: null,
      snackbar: false,
      toast_text: "",
    };
  },

  methods: {
    ...mapMutations(["setLoginState"]),

    open_change_password_dialog() {
      if (this.$refs.changePasswordForm) {
        this.$refs.changePasswordForm.reset();
      }
      this.change_password_dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    close_change_password_dialog() {
      this.change_password_dialog = false;
      this.$refs.changePasswordForm.reset();
      this.reject();
    },

    submitChangePassword() {
      if (this.$refs.changePasswordForm.validate()) {
        this.$api
          .put("/change-password", {
            old_password: this.old_password,
            new_password: this.new_password,
          })
          .then((response) => {
            localStorage.setItem("token", response.data.token);
            this.setLoginState(true);
            this.$refs.changePasswordForm.reset();
            this.change_password_dialog = false;
            this.resolve(true);
          })
          .catch((error) => {
            console.log(error);
            this.toast_text = "更改密码失败，请稍后再试";
            if (error.response && error.response.status === 400) {
              if (error.response.data && error.response.data.message) {
                this.toast_text = error.response.data.message;
              }
            }
            this.snackbar = true;
          });
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
