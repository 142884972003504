import axios from "axios";
import router from "../router";

// const api = axios.create({
//   baseURL: "https://www.ahlonglu.com/api",
//   timeout: 5000,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

const api = axios.create({
  baseURL: "/api",
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

// 通过拦截器处理token
api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // 未认证，跳转到登录页面
      router.push("/login");
    } else {
      // 其他错误，打印错误信息
      console.log(error);
    }
    return Promise.reject(error);
  },
);

export default api;
