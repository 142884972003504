<template>
  <div
    class="d-none d-md-block"
    style="position: fixed; bottom: 0; right: 0; height: 166px; width: 300px"
    :style="{ bottom: widgetBottom }"
  >
    <v-hover v-slot="{ hover }">
      <v-expand-transition>
        <div
          class="rounded white--text transition-fast-in-fast-out py-0 d-flex align-center"
          style="width: 300px; height: 55px; background-color: #d6000f"
          :style="{ 'margin-left': hover ? '2px' : '240px' }"
        >
          <div class="d-inline-block mx-4" style="width: 55px; height: 55px">
            <v-icon size="28" color="white" class="d-block">mdi-phone-incoming-outline</v-icon>
            <span class="d-block body-2">电话</span>
          </div>
          <div
            class="d-inline-block mx-1 white--text py-0 align-center"
            style="height: 55px; line-height: 55px; display: flex; align-items: center"
          >
            <a class="white--text" :href="'tel:' + company_profile.company_phone">
              {{ company_profile.company_phone }}
            </a>
          </div>
        </div>
      </v-expand-transition>
    </v-hover>
    <v-hover v-slot="{ hover }" style="margin-top: 1px">
      <v-expand-transition>
        <div
          class="rounded white--text transition-fast-in-fast-out py-0 d-flex align-center"
          style="width: 300px; height: 55px; background-color: #d6000f"
          :style="{ 'margin-left': hover ? '2px' : '240px' }"
        >
          <div class="d-inline-block mx-4" style="width: 55px; height: 55px">
            <v-icon size="28" color="white" class="d-block">mdi-email-outline</v-icon>
            <span class="d-block body-2">邮箱</span>
          </div>
          <div
            class="d-inline-block mx-1 white--text py-0 align-center"
            style="height: 55px; line-height: 55px; display: flex; align-items: center"
          >
            <a class="white--text" :href="'mailto:' + company_profile.company_email">
              {{ company_profile.company_email }}
            </a>
          </div>
        </div>
      </v-expand-transition>
    </v-hover>
    <v-hover v-slot="{ hover }" style="margin-top: 1px">
      <v-expand-transition>
        <div
          class="rounded white--text transition-fast-in-fast-out py-0 d-flex align-center"
          style="width: 300px; height: 55px; background-color: #d6000f"
          :style="{ 'margin-left': hover ? '220px' : '240px' }"
          @click="scrollToTop"
        >
          <div class="d-inline-block mx-4" style="width: 55px; height: 55px">
            <v-icon size="28" color="white" class="d-block">mdi-arrow-collapse-up</v-icon>
            <span class="d-block body-2">TOP</span>
          </div>
        </div>
      </v-expand-transition>
    </v-hover>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SiteWidget",
  data: () => ({
    windowHeight: null,
  }),
  computed: {
    ...mapState(["company_profile"]),

    widgetBottom() {
      return `${this.windowHeight / 3}px`;
    },
  },
  mounted() {
    this.windowHeight = window.innerHeight;
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleResize() {
      this.windowHeight = window.innerHeight;
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
