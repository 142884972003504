<template>
  <div>
    <v-carousel
      hide-delimiters
      :show-arrows="false"
      cycle
      interval="6000"
      reverse-transition="fade-transition"
      transition="fade-transition"
      height="100%"
    >
      <v-carousel-item v-for="(image_link, i) in carousel_images" :key="i" :src="image_link"></v-carousel-item>
    </v-carousel>
    <div class="" style="background-color: #81cbc4">
      <v-container class="py-8">
        <div class="text-center"><h1 class="red--text">强大的市场服务能力</h1></div>
        <div class="d-flex justify-center py-2">
          <div style="width: 100px; border-top: 2px solid red"></div>
        </div>
        <div class="text-center">
          <h4 class="blue-grey--text">精准洞察客户需求，提供专业服务和优质产品</h4>
        </div>
        <div class="d-flex flex-column flex-md-row flex-md-wrap align-center justify-center pt-5">
          <v-card
            tile
            hover
            height="90"
            :width="$vuetify.breakpoint.mdAndUp ? '350' : '100%'"
            class="ma-3 pa-2 text-center"
          >
            <img :src="require('../assets/logo/darunfa.png')" alt="大润发" class="" height="100%" contain />
          </v-card>
          <v-card
            tile
            hover
            height="90"
            :width="$vuetify.breakpoint.mdAndUp ? '350' : '100%'"
            class="ma-3 pa-2 text-center"
          >
            <img :src="require('../assets/logo/huarunwanjia.jpg')" alt="华润万家" class="" height="100%" contain />
          </v-card>
          <v-card
            tile
            hover
            height="90"
            :width="$vuetify.breakpoint.mdAndUp ? '350' : '100%'"
            class="ma-3 pa-2 text-center"
          >
            <img :src="require('../assets/logo/jiajiayue.png')" alt="家家悦" class="" height="100%" contain />
          </v-card>
          <v-card
            tile
            hover
            height="90"
            :width="$vuetify.breakpoint.mdAndUp ? '350' : '100%'"
            class="ma-3 pa-2 text-center"
          >
            <img :src="require('../assets/logo/shijilianhua.jpg')" alt="世纪联华" class="" height="100%" contain />
          </v-card>
          <v-card
            tile
            hover
            height="90"
            :width="$vuetify.breakpoint.mdAndUp ? '350' : '100%'"
            class="ma-3 pa-2 text-center"
          >
            <img :src="require('../assets/logo/sanjiang.jpg')" alt="SANJIANG" class="" height="100%" contain />
          </v-card>
        </div>
      </v-container>
    </div>
    <div class="cyan lighten-5">
      <v-container class="py-8">
        <div class="text-center"><h1 class="red--text">我们的产品</h1></div>
        <div class="d-flex justify-center py-2">
          <div style="width: 100px; border-top: 2px solid red"></div>
        </div>
        <div class="text-center">
          <h4 class="blue-grey--text">产品系列</h4>
        </div>
        <div class="pt-5">
          <v-row align="start" v-if="catalogs">
            <v-col cols="12" md="2" class="px-0">
              <v-item-group v-model="catalog_window" class="shrink d-none d-md-block" mandatory>
                <v-item v-for="(catalog_item, i) in catalogs" :key="i" v-slot="{ active, toggle }">
                  <v-card
                    flat
                    tile
                    color="transparent"
                    class="px-6 py-7"
                    style="z-index: 2"
                    :class="active ? 'slide-right white--text catalog-item-active' : ''"
                    @mouseover="toggle"
                    :to="{
                      name: 'catalogList',
                      params: { catalog_uri: catalog_item.uri },
                    }"
                  >
                    {{ catalog_item.title }}
                  </v-card>
                </v-item>
              </v-item-group>
              <div class="d-block d-md-none text-center">
                <v-btn text class="d-inline" @click="home_catalog_prev">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-item-group v-model="catalog_window" class="text-center d-inline" mandatory>
                  <v-item v-for="n in catalogs.length" :key="`btn-${n}`" v-slot="{ active, toggle }">
                    <v-btn :input-value="active" icon @click="toggle">
                      <v-icon>mdi-record</v-icon>
                    </v-btn>
                  </v-item>
                </v-item-group>
                <v-btn text class="d-inline" @click="home_catalog_next">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" md="10" class="px-0">
              <v-window v-model="catalog_window" class="" :vertical="$vuetify.breakpoint.mdAndUp ? true : false">
                <v-window-item v-for="(catalog_item, i) in catalogs" :key="i">
                  <div class="d-flex justify-center d-block d-md-none text-body-1 pb-5">
                    {{ catalog_item.title }}
                  </div>
                  <v-card
                    flat
                    tile
                    color="transparent"
                    :to="{
                      name: 'catalogList',
                      params: { catalog_uri: catalog_item.uri },
                    }"
                  >
                    <v-img :src="catalog_item.picture" aspect-ratio="1.8" class=""></v-img>
                  </v-card>
                </v-window-item>
              </v-window>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
    <div class="green lighten-5">
      <v-container class="py-8">
        <div class="text-center"><h1 class="red--text">市场热销产品</h1></div>
        <div class="d-flex justify-center py-2">
          <div style="width: 100px; border-top: 2px solid red"></div>
        </div>
        <div class="text-center">
          <h4 class="blue-grey--text">受欢迎的</h4>
        </div>
        <div class="pt-8">
          <v-row align="start">
            <v-col cols="12" md="4">
              <v-row>
                <v-col v-for="(product_item, i) in popular_products.col1" :key="i" cols="12">
                  <v-card
                    color=""
                    flat
                    tile
                    :to="{
                      name: 'productDetail',
                      params: { product_uri: product_item.uri },
                    }"
                    class="overflow-hidden"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-img
                        :src="product_item.picture[0]"
                        contain
                        :class="hover ? 'scale-in-center' : 'scale-out-center'"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="red"></v-progress-circular>
                          </v-row>
                        </template>
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="d-flex transition-fast-in-fast-out v-card--reveal text-center justify-center align-center"
                            style="
                              height: 50px;
                              width: 100%;
                              position: absolute;
                              top: 50%;
                              background-color: rgba(225, 245, 254, 0.8);
                            "
                          >
                            {{ product_item.title }}
                          </div>
                        </v-expand-transition>
                      </v-img>
                    </v-hover>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4">
              <v-row>
                <v-col v-for="(product_item, i) in popular_products.col2" :key="i" cols="12">
                  <v-card
                    color=""
                    flat
                    tile
                    :to="{
                      name: 'productDetail',
                      params: { product_uri: product_item.uri },
                    }"
                    class="overflow-hidden"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-img
                        :src="product_item.picture[0]"
                        contain
                        :class="hover ? 'scale-in-center' : 'scale-out-center'"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="red"></v-progress-circular>
                          </v-row>
                        </template>
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="d-flex transition-fast-in-fast-out v-card--reveal text-center justify-center align-center"
                            style="
                              height: 50px;
                              width: 100%;
                              position: absolute;
                              top: 50%;
                              background-color: rgba(225, 245, 254, 0.8);
                            "
                          >
                            {{ product_item.title }}
                          </div>
                        </v-expand-transition>
                      </v-img>
                    </v-hover>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="4">
              <v-row>
                <v-col v-for="(product_item, i) in popular_products.col3" :key="i" cols="12">
                  <v-card
                    color=""
                    flat
                    tile
                    :to="{
                      name: 'productDetail',
                      params: { product_uri: product_item.uri },
                    }"
                    class="overflow-hidden"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-img
                        :src="product_item.picture[0]"
                        contain
                        :class="hover ? 'scale-in-center' : 'scale-out-center'"
                      >
                        <template v-slot:placeholder>
                          <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="red"></v-progress-circular>
                          </v-row>
                        </template>
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="d-flex transition-fast-in-fast-out v-card--reveal text-center justify-center align-center"
                            style="
                              height: 50px;
                              width: 100%;
                              position: absolute;
                              top: 50%;
                              background-color: rgba(225, 245, 254, 0.8);
                            "
                          >
                            {{ product_item.title }}
                          </div>
                        </v-expand-transition>
                      </v-img>
                    </v-hover>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      catalog_window: 0,
    };
  },

  computed: {
    ...mapState(["company_profile", "catalogs", "is_login", "carousel_images"]),

    catalog_length() {
      if (this.catalogs) {
        return this.catalogs.length;
      } else {
        return 0;
      }
    },

    popular_products() {
      const product_obj = {
        col1: [],
        col2: [],
        col3: [],
      };
      if (this.catalogs) {
        const products = [];
        for (let i = 0; i < this.catalogs.length; i++) {
          for (let j = 0; j < this.catalogs[i].products.length; j++) {
            if (this.catalogs[i].products[j].is_popular) {
              products.push(this.catalogs[i].products[j]);
            }
          }
        }
        if (products.length > 0) {
          products.sort((a, b) => {
            return new Date(b.create_at) - new Date(a.create_at);
          });
          for (let n = 0; n < products.length; n++) {
            if (n % 3 === 0) {
              product_obj.col1.push(products[n]);
            } else if (n % 3 === 1) {
              product_obj.col2.push(products[n]);
            } else if (n % 3 === 2) {
              product_obj.col3.push(products[n]);
            } else {
              product_obj.col1.push(products[n]);
            }
          }
        }
      }
      return product_obj;
    },
  },

  methods: {
    ...mapMutations(["setCatalogs", "setCarouselImages"]),

    home_catalog_next() {
      this.catalog_window = this.catalog_window + 1 === this.catalog_length ? 0 : this.catalog_window + 1;
    },
    home_catalog_prev() {
      this.catalog_window = this.catalog_window - 1 < 0 ? this.catalogs.catalog_length - 1 : this.catalog_window - 1;
    },

    get_site_carousel_images() {
      this.$api
        .get("/site-config/1")
        .then((response) => {
          if (response.data) {
            this.setCarouselImages(response.data.carousel_images);
          } else {
            console.log("读取轮播图片失败");
          }
        })
        .catch(() => {
          console.log("获取配置失败");
        });
    },
  },

  created() {
    document.title = this.company_profile.company_name;
  },

  mounted() {
    if (this.carousel_images.length === 0) {
      this.get_site_carousel_images();
    }
  },

  components: {},
};
</script>

<style scoped>
.slide-right {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.catalog-item-active {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
  }
}

.scale-in-center {
  -webkit-animation: scale-in-center 0.5s ease-in-out both;
  animation: scale-in-center 0.5s ease-in-out both;
}

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 1;
  }
}

.scale-out-center {
  -webkit-animation: scale-out-center 0.5s ease-in-out both;
  animation: scale-out-center 0.5s ease-in-out both;
}

/**
 * ----------------------------------------
 * animation scale-out-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
</style>
