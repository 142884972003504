<template>
  <div>
    <div>
      <v-system-bar app dark color="#81cbc4">
        <v-container class="white--text">
          <v-row>
            <v-col cols="6" align="start" class="d-none d-md-inline">
              <div class="">{{ company_profile.company_name }}</div>
            </v-col>
            <v-col cols="6" align="end" class="d-none d-md-inline">
              <div class="">
                <span class="px-3"><router-link to="/contact" class="white--text">联系我们</router-link></span>
                <!-- <span class="">{{ company_profile.company_phone }}</span> -->
                <div v-if="is_login" class="d-inline">
                  <v-divider vertical class="mx-3" style="height: 16px"></v-divider>
                  <v-menu open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark v-bind="attrs" v-on="on" style="line-height: 22px">mdi-cog</v-icon>
                    </template>
                    <v-list dense>
                      <v-list-item dense>
                        <v-list-item-title @click="call_open_site_settings_dialog">网站信息设置</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item dense>
                        <v-list-item-title @click="call_open_upload_carousel_image_dialog">
                          首页大图设置
                        </v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item dense>
                        <v-list-item-title @click="call_open_site_backup_dialog">下载网站备份</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item dense>
                        <v-list-item-title @click="call_open_change_password_dialog">修改密码</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item dense>
                        <v-list-item-title @click="logout">退出登录</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-system-bar>
    </div>
    <div>
      <v-app-bar app color="white" height="80" flat elevation="3">
        <v-container class="py-0 fill-height">
          <router-link to="/">
            <v-img
              class="d-flex ml-n3 ml-md-0"
              contain
              :max-width="logo_size"
              :src="require('../assets/logo.png')"
            ></v-img>
          </router-link>
          <div style="position: absolute; left: 50%; margin-left: -100px; margin-top: -100px">
            <div
              class="white--text mx-auto mt-n2 rounded-circle text-center d-flex flex-column-reverse align-end py-1"
              style="
                width: 200px;
                height: 90px;
                background-color: #81cbc4;
                text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.9);
              "
            >
              <div class="mx-auto">始于1998</div>
              <div class="mx-auto">专 注 于 厨 房</div>
            </div>
          </div>
          <v-spacer class="d-flex"></v-spacer>

          <v-btn
            class="d-none d-md-flex mt-5 text-h6 font-weight-black"
            v-for="item of navigation_links"
            :key="item.name"
            :href="item.link"
            text
          >
            {{ item.name }}
          </v-btn>

          <v-btn rounded depressed class="d-none d-md-flex ml-3 mt-5 pr-10" @click="call_open_search_product_dialog">
            <v-icon left color="primary">mdi-magnify</v-icon>
            搜索
          </v-btn>
          <v-app-bar-nav-icon class="d-flex d-md-none" @click="drawer = !drawer"></v-app-bar-nav-icon>
        </v-container>
      </v-app-bar>
    </div>
    <div>
      <update-site-settings ref="UpdateSiteSettings"></update-site-settings>
      <upload-carousel-image ref="UploadCarouselImage"></upload-carousel-image>
      <change-password ref="ChangePassword"></change-password>
      <get-site-backup ref="GetSiteBackup"></get-site-backup>
      <search-product ref="SearchProduct"></search-product>
      <v-snackbar centered elevation="24" v-model="snackbar" timeout="5000">
        {{ toast_text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">关闭</v-btn>
        </template>
      </v-snackbar>
    </div>
    <v-navigation-drawer app v-model="drawer" fixed right temporary>
      <v-list dense nav>
        <div v-for="item in navigation_links" :key="item.name">
          <v-list-item :to="item.link">
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </div>
        <div>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-btn rounded depressed @click="call_open_search_product_dialog">
                  <v-icon left color="primary">mdi-magnify</v-icon>
                  搜索
                </v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ChangePassword from "../components/ChangePassword";
import UploadCarouselImage from "../components/UploadCarouselImage";
import UpdateSiteSettings from "../components/UpdateSiteSettings";
import GetSiteBackup from "../components/GetSiteBackup";
import SearchProduct from "../components/SearchProduct";

export default {
  name: "SiteHeader",
  components: {
    UpdateSiteSettings,
    UploadCarouselImage,
    GetSiteBackup,
    ChangePassword,
    SearchProduct,
  },

  data: () => ({
    drawer: false,
    navigation_links: [
      { name: "首页", link: "/" },
      { name: "产品中心", link: "/catalog/all" },
      { name: "公司简介", link: "/about" },
      { name: "联系我们", link: "/contact" },
    ],
    snackbar: false,
    toast_text: "",
  }),
  methods: {
    ...mapMutations(["setLoginState", "setCarouselImages"]),

    call_open_site_settings_dialog() {
      this.$refs.UpdateSiteSettings.open_site_settings_dialog()
        .then((result) => {
          if (result) {
            this.toast_text = "更新网站信息成功";
            this.snackbar = true;
          } else {
            this.toast_text = "更新网站信息失败，请稍后再试";
            this.snackbar = true;
          }
        })
        .catch(() => {});
    },

    call_open_upload_carousel_image_dialog() {
      this.$refs.UploadCarouselImage.open_upload_carousel_image_dialog()
        .then((imageUrls) => {
          this.$api
            .put("/site-config/1", {
              carousel_images: imageUrls,
            })
            .then(() => {
              this.setCarouselImages(imageUrls);
              this.toast_text = "设置首页轮播图片成功";
              this.snackbar = true;
            })
            .catch(() => {
              this.toast_text = "设置首页轮播图片失败，请稍后再试";
              this.snackbar = true;
            });
        })
        .catch(() => {});
    },

    call_open_change_password_dialog() {
      this.$refs.ChangePassword.open_change_password_dialog()
        .then((result) => {
          if (result) {
            this.toast_text = "修改密码成功";
            this.snackbar = true;
          } else {
            this.toast_text = "修改密码失败，请稍后再试";
            this.snackbar = true;
          }
        })
        .catch(() => {});
    },

    call_open_site_backup_dialog() {
      this.$refs.GetSiteBackup.open_site_backup_dialog();
    },

    call_open_search_product_dialog() {
      this.drawer = false;
      this.$refs.SearchProduct.open_search_product_dialog();
    },

    logout() {
      localStorage.removeItem("token");
      this.setLoginState(false);
    },
  },
  computed: {
    ...mapState(["company_profile", "is_login"]),

    logo_size() {
      return this.$vuetify.breakpoint.mdAndUp ? 100 : 80;
    },
  },
  mounted() {
    const token = localStorage.getItem("token");
    if (token) {
      this.$api
        .get("/token-info")
        .then((response) => {
          if (response.data.user_id > 0) {
            this.setLoginState(true);
          } else {
            this.setLoginState(false);
            localStorage.removeItem("token");
          }
        })
        .catch((error) => {
          this.setLoginState(false);
          if (error.response && error.response.status === 401) {
            localStorage.removeItem("token");
          }
          console.log("获取token信息失败！");
        });
    }
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
