<template>
  <div>
    <v-footer color="grey darken-1" class="py-5">
      <v-card flat tile width="100%" color="grey darken-1" class="footer-text">
        <v-container class="">
          <v-row>
            <v-col cols="12" md="4" class="px-2 px-md-6" style="position: relative">
              <v-card flat tile color="rgba(0, 0, 0, 0)">
                <v-card-text class="footer-text">
                  <v-img
                    class=""
                    contain
                    position="absolute"
                    top="0"
                    left="0"
                    :max-height="50"
                    :src="require('../assets/logo.png')"
                  ></v-img>
                </v-card-text>
                <v-card-text class="footer-text pb-2">
                  <h3 class="footer-text">联系我们</h3>
                </v-card-text>
                <v-list-item dense>
                  <v-list-item-icon class="mr-3">
                    <v-icon small class="footer-text">mdi-map-marker</v-icon>
                    <span class="pl-1 footer-text">地址:</span>
                  </v-list-item-icon>
                  <v-list-item-content class="footer-text">
                    {{ company_profile.company_address }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-icon class="mr-3">
                    <v-icon small class="footer-text">mdi-phone</v-icon>
                    <span class="pl-1 footer-text">电话:</span>
                  </v-list-item-icon>
                  <v-list-item-content class="footer-text">
                    <a class="footer-text" :href="'tel:' + company_profile.company_phone">
                      {{ company_profile.company_phone }}
                    </a>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-icon class="mr-3">
                    <v-icon small class="footer-text">mdi-email</v-icon>
                    <span class="pl-1 footer-text">邮件:</span>
                  </v-list-item-icon>
                  <v-list-item-content class="footer-text">
                    <a class="footer-text" :href="'mailto:' + company_profile.company_email">
                      {{ company_profile.company_email }}
                    </a>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" class="px-2 px-md-8 d-none d-md-inline">
              <v-card flat tile color="rgba(0, 0, 0, 0)">
                <v-card-text class="footer-text pb-2">
                  <h3 class="footer-text">产品中心</h3>
                </v-card-text>
                <v-card-text class="py-1" v-for="catalog_item in catalogs" :key="catalog_item.title">
                  <a class="footer-text" :href="'/catalog/' + catalog_item.uri">{{ catalog_item.title }}</a>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" class="px-2 px-md-8 d-none d-md-inline">
              <v-card flat tile color="rgba(0, 0, 0, 0)">
                <v-card-text class="footer-text pb-2">
                  <h3 class="footer-text">网站链接</h3>
                </v-card-text>
                <v-card-text class="py-1" v-for="item in navigation_links" :key="item.name">
                  <a class="footer-text" :href="item.link">{{ item.name }}</a>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-divider dark class="my-5"></v-divider>
        <v-row class="mb-3">
          <v-col cols="12" align="center" class="py-0">
            Copyright &copy; {{ new Date().getFullYear() }} &bull; {{ company_profile.company_name }} &bull; All rights
            reserved.
          </v-col>
          <v-col cols="12" align="center" class="py-1">
            <a href="https://beian.miit.gov.cn" target="_blank" class="footer-text">皖ICP备2023006962号</a>
          </v-col>
        </v-row>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "SiteFooter",
  data: () => ({
    drawer: null,
    navigation_links: [
      { name: "首页", link: "/" },
      { name: "产品中心", link: "/catalog/all" },
      { name: "公司简介", link: "/about" },
      { name: "联系我们", link: "/contact" },
      { name: "管理登录", link: "/login" },
    ],
  }),
  computed: {
    ...mapState(["company_profile", "catalogs"]),
  },
  methods: {
    ...mapMutations(["setCatalogs", "setCompanyProfile"]),

    get_company_profile() {
      this.$api
        .get("/site-config/1")
        .then((response) => {
          this.setCompanyProfile({
            company_address: response.data.company_address,
            company_email: response.data.company_email,
            company_phone: response.data.company_phone,
          });
        })
        .catch(() => {
          console.log("获取配置失败");
        });
    },
    get_all_catalogs() {
      this.$api
        .get("/catalog")
        .then((response) => {
          if (response.data) {
            this.setCatalogs(response.data);
          }
        })
        .catch(() => {
          console.log("获取分类失败");
        });
    },
  },

  mounted() {
    if (this.company_profile.company_address === null) {
      this.get_company_profile();
    }
    if (this.catalogs === null) {
      this.get_all_catalogs();
    }
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.footer-text {
  color: #81cbc4;
}
</style>
